<div class="content ocultar-cell" role="main">

    <div class="card-container ocultar-cell">
        <div class="colores">
            <!-- ************************************ Icono Video ************************************ -->
            <a class="circle-link" title="VIDEO" href="#" target="_blank" rel="noopener">

                <img width="51.81" height="53.447" src="../../assets/images/MENU INFERIOR/MENU_INFERIOR_BOTON_VIDEO.png" alt="">
                <p class="circle-link-text">VIDEO</p>

            </a>
            <!-- ************************************ Icono home ************************************ -->
            <a class="circle-link" title="HOME" [routerLink]="[ '#' ]" rel="noopener">

                <img width="51.81" height="53.447" src="../../assets/images/MENU INFERIOR/MENU_INFERIOR_BOTON_HOME.png" alt="">
                <p class="circle-link-text">HOME</p>

            </a>
            <!-- ************************************ Icono Pabellón 1************************************ -->
            <a class="circle-link" [routerLink]="[ '../Pabellon1' ]" title="Pabellón 1">

                <img width="51.81" height="53.447" src="../../assets/images/Menu-inferior/pabellon1.png" alt="">
                <p class="circle-link-text">HALL1</p>
            </a>
            <!-- ************************************ Icono Pabellón 2************************************ 
            <a class="circle-link" [routerLink]="[ '../pabellon2' ]" title="Pabellón 2">

                <img width="51.81" height="53.447" src="../../assets/images/Menu-inferior/pabellon2.png" alt="">
                <p class="circle-link-text">HALL2</p>
            </a>-->
            <!-- ************************************ Icono EnVivo ************************************ -->
            <a class="circle-link" title="LIVE" href="#" target="_blank" rel="noopener">

                <img width="51.81" height="53.447" src="../../assets/images/MENU INFERIOR/MENU_INFERIOR_BOTON_VIVO.png " alt="">
                <p class="circle-link-text">LIVE</p>

            </a>
            <!-- ************************************ Icono Programa o Agenda ************************************ -->
            <a class="circle-link" title="Program" href="#" target="_blank" rel="noopener">

                <img width="51.81" height="53.447" src="../../assets/images/MENU INFERIOR/MENU_INFERIOR_PROGRAMA.png" alt="">
                <p class="circle-link-text">PROGRAM</p>

            </a>
            <!-- ************************************ Icono Sponsor ************************************ -->
            <a class="circle-link" title="Sponsor" href="#" target="_blank" rel="noopener">

                <img width="51.81" height="53.447" src="../../assets/images/MENU INFERIOR/MENU_INFERIOR_BOTON_SPONSORS.png" alt="">
                <p class="circle-link-text">SPONSOR</p>

            </a>
            <!-- ************************************ Icono Chat ************************************ -->
            <a class="circle-link" title="Chat" (click)="openWindowChat()" rel="noopener">

                <img width="51.81" height="53.447" src="../../assets/images/MENU INFERIOR/MENU_INFERIOR_CHAT.png" alt="">
                <p class="circle-link-text">CHAT</p>

            </a>

        </div>
    </div>

    <div class="contSecundario">
        <div id="contChat" class="contChat" *ngIf="windowChatVisible">
            <app-index></app-index>
        </div>
    </div>
    <!-- Footer -->


</div>