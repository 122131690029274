<div>
    <div class="social-bar">
        <a data-toggle="modal" data-target="#exampleModal" class="icon " title="Tutorial Business Fair"><img width="31.81" height="33.447" src="../../assets/images/LOGO_+_SVG (1)/info_white_24dp.svg" alt=""></a>
        <a [routerLink]="[ '../Pabellon1' ]" title="Pabellón 1 Business Fair" class="icon "><img width="31.81" height="33.447" src="../../assets/images/LOGO_+_SVG (1)/looks_one_white_24dp.svg" alt=""></a>
        <!--<a [routerLink]="[ '../pabellon2' ]" title="Pabellón 2 Business Fair" class="icon"><img width="31.81" height="33.447" src="../../assets/images/LOGO_+_SVG (1)/looks_two_white_24dp.svg" alt=""> </a>-->
    </div>
    
    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"> Tutorial </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
                </div>
                <div class="modal-body">
                    <!-- Primer parrafo tutorial -->
                    <div class="parra-1">
                        <p>Haga Click y gire el mouse para mover la camara</p>
                        <img src="../../assets/images/LOGO_+_SVG (1)/mouse_black_24dp.svg" img width="61.81" height="63.447" alt="">
                    </div>
    
                    <!-- Segundo Parrafo tutorial -->
                    <div class="parra-2">
                        <p>Use las teclas WASD o las flechas para moverse por el paballón</p>
                        <img src="../../assets/images/LOGO_+_SVG (1)/FLECHAS-negras.svg" img width="61.81" height="63.447" alt="">
                        <img src="../../assets/images/LOGO_+_SVG (1)/teclas-negras.svg" img width="61.81" height="63.447" alt="">
                    </div>
    
                    <!-- Tercero Parrafo tutorial -->
                    <div class="parra-3">
                        <p>Para los dispositivos moviles utilice el circulo blanco o Joystick que se encuentra ubicado en la parte inferior derecha para moverse para adelante, atras, a la derecha y a la izquierda</p>
                        <img src="../../assets/images/LOGO_+_SVG (1)/joystick.svg" img width="61.81" height="63.447" alt="">
                    </div>
    
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
    
                </div>
            </div>
        </div>
    </div>
</div>