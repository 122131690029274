<div class="video-container">

    <div class="mover">
        <input type="checkbox" id="btn-modal">
        <label for="btn-modal" class="lbl-modal"> <img src="../../assets/images/boton-de-play-blanco.svg" alt=""> </label>

        <div class="modal">
            <div class="contenedor">

                <label for="btn-modal"> <img src="../../assets/images/close.png" alt=""> </label>
                <div class="contenido">

                    <iframe width="860" height="515" src="www.youtube.com/embed/3SU5fOmJJWE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>


                </div>

            </div>

        </div>
    </div>
</div>