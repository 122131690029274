<div class="layout-wrapper d-lg-flex contMain">
  <div class="chat-leftsidebar mr-lg-1">
    <div class="tab-content">
      <div id="pills-chat" role="tabpanel" aria-labelledby="pills-chat-tab" *ngIf="activetab === 2">
        <app-chats (openRoomChat)="openRoomChat($event)"></app-chats>
      </div>
    </div>
  </div>

  <div class="user-chat w-100 contSalaChat" id="chat-room" *ngIf="chatRoomVisible">
    <div class="d-lg-flex">
      <div class="w-100">
        <div class="p-3 p-lg-4 border-bottom">
          <div class="row align-items-center">
            <div class="col-sm-12 col-12">
              <div class="media align-items-center">
                <div class="d-block  mr-2">
                  <a href="javascript: void(0);" class="user-chat-remove text-muted font-size-16 p-2"
                    (click)="closeUserChat()"><i class="ri-arrow-left-s-line"></i></a>
                </div>
                <!-- <div class="mr-3">
                  <img src="assets/images/users/avatar-4.jpg" class="rounded-circle avatar-xs" alt="">
                </div> -->
                <div class="media-body overflow-hidden">
                  <h5 class="font-size-16 mb-0 text-truncate">
                    <a class="text-reset user-profile-show" id="nomUserChat">Chat en vivo</a>
                    <!-- <i class="ri-record-circle-fill font-size-10 text-success d-inline-block ml-1"></i> -->
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <perfect-scrollbar class="chat-conversation p-3 p-lg-4" id="contScroll">
          <ul class="list-unstyled mb-0" id="contMSJChat">

          </ul>
        </perfect-scrollbar>

        <div class="p-3 p-lg-4 border-top mb-0">
          <div class="row no-gutters">
            <div class="col">
              <div>
                <input [(ngModel)]="textoMessage" type="text" id="contMessage"
                  class="form-control form-control-lg bg-light border-light"
                  (keydown.enter)="invSetNewMessage()" placeholder="Enter Message...">
              </div>
            </div>
            <div class="col-auto">
              <div class="chat-input-links ml-md-2">
                <ul class="list-inline mb-0">
                  <li class="list-inline-item">
                    <button type="submit"
                      class="btn btn-primary font-size-16 btn-lg chat-send waves-effect waves-light"
                      style="background-color: #0c71c3 !important;" (click)="invSetNewMessage()">
                      <i class="ri-send-plane-2-fill"></i>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <app-profile-detail></app-profile-detail>
    </div>
  </div>
</div>
