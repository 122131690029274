import {Component, OnInit, ElementRef, HostListener} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as firebase from 'firebase/app';
import 'firebase/database';

@Component({
  // selector: 'app-aframe-gala3d',
  templateUrl: './aframe-gala3d.component.html',
  styleUrls: ['./aframe-gala3d.component.scss']
})
export class AframeGala3dComponent implements OnInit {
    elem: any;
    aframe: any;
    timeout: any;

  constructor(protected sanitizer: DomSanitizer, ref: ElementRef) {
    this.elem = ref.nativeElement;
  }
  windowChatVisible = false;
  gltfLoaderInstance = null;
  sceneLoaded = false
  loaded = false;
  hall = null;
  // Video de Stand Activo
  activeIframeVideo: any = null;
  // Types
  types = {
    oro: null,
    oro_dos: null,
    bronce: null,
    plata: null,
  };
  brands = {
    bronce: {},
    plata: {}
  };

  // @ts-ignore
  mouse = new THREE.Vector2();
  // @ts-ignore
  raycaster = new THREE.Raycaster();

  async ngOnInit(): Promise<void> {
    try {
      const controller = this;
      // Loader Instance Init
      // @ts-ignore
      this.gltfLoaderInstance = new AFRAME.THREE.GLTFLoader();
      this.gltfLoaderInstance.setCrossOrigin('anonymous');

      // Hall Init
      const hall = await this.gltfLoaderHall();

      // Types init
      const oro = await this.gltfLoaderGold();
      // tslint:disable-next-line:variable-name
      this.types.plata = await this.getStandType('plata', false);
      this.types.bronce = await this.getStandType('bronce', false);

      // Load Bronce data
      this.brands.bronce = await this.getBrandsByType('bronce');
      this.brands.plata = await this.getBrandsByType('plata');
      const promises = Object.values(this.brands.bronce)
        .map(async (item) => await this.standJsonToGltf(item));
      const promisesPlata = Object.values(this.brands.plata)
        .map(async (item) => await this.standJsonToGltf(item));
      const bronceObjects = await Promise.all(promises);
      const plataObjects = await Promise.all(promisesPlata);

      this.loaded = true;

      /// HIDE LOADER HERE
      // @ts-ignore
      AFRAME.registerComponent('check-scene-has-loaded', {
        // tslint:disable-next-line:typedef
        init() {
          const scene = this.el;
          if (scene.hasLoaded) {
            controller.sceneLoaded = true;
          } else {
            scene.addEventListener('loaded', () => {
              controller.sceneLoaded = true;
            });
          }
        },
        remove() {
          // @ts-ignore
          delete AFRAME.components['check-scene-has-loaded'];
          // @ts-ignore
          delete AFRAME.components['debug-cursor'];
          // @ts-ignore
          delete AFRAME.components['raycastable'];
          // @ts-ignore
          delete AFRAME.components['stand-link'];
          // @ts-ignore
          delete AFRAME.components['feria'];
        }
      });
      // @ts-ignore
      AFRAME.registerComponent('debug-cursor', {
        // tslint:disable-next-line:typedef
        init() {
          this.el.addEventListener('click', evt => {
            this.log('click', evt.detail.intersectedEl, 'blue');
          });
        },
        // tslint:disable-next-line:typedef
        log(event, intersectedEl, color) {
          if (intersectedEl.id) {
            console.log(`%c[${event}] ${intersectedEl.id}`, `color: ${color}`);
          } else {
            console.log(`%c[${event}]`, `color: ${color}`);
            console.log(intersectedEl);
          }
        }
      });
      // @ts-ignore
      AFRAME.registerComponent('raycastable', {});
      // @ts-ignore
      AFRAME.registerComponent('stand-link', {
        schema: {
          video: { type: 'string' },
          href: { type: 'string' },
          chat: { type: 'string' },
          whatsapp: { type: 'string' },
        },
        // tslint:disable-next-line:typedef
        init() {
          const el = this.el;
          if (this.data.href) {
            // Navigate
            el.addEventListener('click', () => {
              setTimeout(() => {
                window.open(this.data.href);
              }, 500);
            });
          }
          if (this.data.chat) {
            // Todo: integrar chat
            controller.windowChatVisible = true;
          }
          if (this.data.whatsapp) {
            el.addEventListener('click', () => {
              setTimeout(() => {
                window.open(`https://api.whatsapp.com/send?phone=${this.data.whatsapp}`);
              }, 500);
            });
          }
        },
      });
      // @ts-ignore
      AFRAME.registerComponent('feria', {
        // tslint:disable-next-line:typedef
        init() {
          const el = this.el; // Entity.
          // @ts-ignore
          const math = THREE.Math;

          // Set Fair
          // @ts-ignore
          const THE_FAIR = new AFRAME.THREE.Group();
          THE_FAIR.position.set(-2, 0, -38);
          //THE_FAIR.rotateY(math.degToRad(90));

          // Set Hall
          // @ts-ignore
          hall.rotateY(math.degToRad(90));

          // Set oro **/
          // @ts-ignore
          oro.position.set(-13, 0.3, 20); // X Y Z
          // @ts-ignore
          oro.rotateY(math.degToRad(90));

          // Isla Bronce 1 **/
          // @ts-ignore
          const islaBronce1 = new AFRAME.THREE.Group();
          islaBronce1.position.set(-20, 0.28, -10);
          islaBronce1.rotateY(math.degToRad(-90));

          // Bronce A
          const bronce1A = bronceObjects[0];
          // @ts-ignore
          bronce1A.position.set(0, 0, 0);
          // @ts-ignore
          islaBronce1.add(bronce1A);
          islaBronce1.add(controller.createBronceMenuFromStandData('stand03', 'a', 'bronce'));

          // Bronce B
          const bronce1B = bronceObjects[1];
          // @ts-ignore
          bronce1B.position.set(-22, 0, 0);
          // @ts-ignore
          islaBronce1.add(bronce1B);
          islaBronce1.add(controller.createBronceMenuFromStandData('stand04', 'b', 'bronce'));

          // Bronce C
          const bronce1C = bronceObjects[2];
          // @ts-ignore
          bronce1C.position.set(22, 0, 0);
          // @ts-ignore
          islaBronce1.add(bronce1C);
          islaBronce1.add(controller.createBronceMenuFromStandData('stand05', 'c', 'bronce'));

          // Isla Bronce 2 **/
          // @ts-ignore
          const islaBronce2 = new AFRAME.THREE.Group();
          islaBronce2.position.set(2, 0.28, -45);
          islaBronce2.rotateY(math.degToRad(180));
          // Bronce A
          const bronce2A = bronceObjects[3];
          // @ts-ignore
          bronce2A.position.set(0, 0, 0);
          // @ts-ignore
          // bronce1A.rotateY(math.degToRad(180));
          islaBronce2.add(bronce2A);
          islaBronce2.add(controller.createBronceMenuFromStandData('stand06', 'a', 'bronce'));

          // Bronce B
          const bronce2B = bronceObjects[4];
          // @ts-ignore
          bronce2B.position.set(-15, 0, 0);
          // @ts-ignore
          islaBronce2.add(bronce2B);
          islaBronce2.add(controller.createBronceMenuFromStandData('stand07', 'b', 'bronce'));

          // Bronce C
          const bronce2C = bronceObjects[5];
          // @ts-ignore
          bronce2C.position.set(15, 0, 0);
          // @ts-ignore
          islaBronce2.add(bronce2C);
          islaBronce2.add(controller.createBronceMenuFromStandData('stand08', 'c', 'bronce'));

          // Isla Bronce 3 **/
          // @ts-ignore
          const islaBronce3 = new AFRAME.THREE.Group();
          islaBronce3.position.set(25, 0.28, -10);
          islaBronce3.rotateY(math.degToRad(90));

          // Bronce A
          const bronce3A = bronceObjects[6];
          // @ts-ignore
          bronce3A.position.set(0, 0, 0);
          // @ts-ignore
          islaBronce3.add(bronce3A);
          islaBronce3.add(controller.createBronceMenuFromStandData('stand09', 'a', 'bronce'));

          // Bronce B
          const bronce3B = bronceObjects[7];
          // @ts-ignore
          bronce3B.position.set(-22, 0, 0);
          // @ts-ignore
          islaBronce3.add(bronce3B);
          islaBronce3.add(controller.createBronceMenuFromStandData('stand10', 'b', 'bronce'));

          // Bronce C
          const bronce3C = bronceObjects[8];
          // @ts-ignore
          bronce3C.position.set(22, 0, 0);
          // @ts-ignore
          islaBronce3.add(bronce3C);
          islaBronce3.add(controller.createBronceMenuFromStandData('stand13', 'c', 'bronce'));

          // isla plata 1 **/
          // @ts-ignore
          const islaPlata1 = new AFRAME.THREE.Group();
          islaPlata1.position.set(12, 0.28, 0);
          islaPlata1.rotateY(math.degToRad(90));

          // Plata A
          const plata1a = plataObjects[0];
          // @ts-ignore
          plata1a.position.set(-5.52, 0, 0);
          // @ts-ignore
          plata1a.rotateY(math.degToRad(180));
          islaPlata1.add(plata1a);
          islaPlata1.add(controller.createBronceMenuFromStandData('stand11', 'pA', 'plata'));

          // Plata B
          const plata1b = plataObjects[1];
          // @ts-ignore
          plata1b.position.set(5.52, 0, -20);
          islaPlata1.add(plata1b);
          islaPlata1.add(controller.createBronceMenuFromStandData('stand12', 'pB', 'plata'));

          // isla plata 2 **/
          // @ts-ignore
          const islaPlata2 = new AFRAME.THREE.Group();
          islaPlata2.position.set(12, 0.28, -20);
          islaPlata2.rotateY(math.degToRad(90));
          // Plata A
          const plata2a = plataObjects[2];
          // @ts-ignore
          plata2a.position.set(-5.52, 0, 0);
          // @ts-ignore
          plata2a.rotateY(math.degToRad(180));
          islaPlata2.add(plata2a);
          islaPlata2.add(controller.createBronceMenuFromStandData('stand21', 'pA', 'plata'));
          // Plata B
          const plata2b = plataObjects[3];
          // @ts-ignore
          plata2b.position.set(5.52, 0, -20);
          islaPlata2.add(plata2b);
          islaPlata2.add(controller.createBronceMenuFromStandData('stand22', 'pB', 'plata'));

          // Add to fair **/
          // @ts-ignore
          const mitad1 = new AFRAME.THREE.Group();

          mitad1.add(islaBronce1);
          mitad1.add(islaBronce2);
          mitad1.add(islaBronce3);
          mitad1.add(islaPlata1);
          mitad1.add(islaPlata2);

          THE_FAIR.add(hall);
          THE_FAIR.add(oro);
          THE_FAIR.add(mitad1);

          el.object3D.add(THE_FAIR);
        },
      });
    } catch (e) {
      alert(`error => ${e}`);
    }
  }

  @HostListener('document:click', ['$event'])
  onMouseClick(event) {
    // calculate mouse position in normalized device coordinates
    // (-1 to +1) for both components
    this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    this.mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;
    this.hoverMenu();
  }

  hoverMenu() {
    // @ts-ignore
    const scene = document.querySelector('a-scene');
    // @ts-ignore
    const camera = document.querySelector('#camera').getObject3D('camera');
    this.raycaster.setFromCamera(this.mouse, camera);
    // @ts-ignore
    const intersects = this.raycaster.intersectObjects(scene.object3D.children, true);
    if (intersects.length > 0 && intersects[0].object && intersects[0].object.userData && intersects[0].object.userData.boton) {
      console.log('userData', intersects[0].object.userData);
      const data = intersects[0].object.userData;
      if (data.name === 'video') {
        this.setVideoIframeURI(data.link);
      }
      if (data.name === 'chat') {
        this.windowChatVisible = true;
        return;
      }
      if (data.name === 'whatsapp') {
        setTimeout(() => {
          window.open(`https://api.whatsapp.com/send?phone=${data.link}`);
        }, 500);
        return;
      }
      setTimeout(() => {
        window.open(data.link);
      }, 500);
    }
  }

  createBronceMenuFromStandData(standName, standPosition, standType) {
    const standPositions = {
      a: [0.2, 0, 0],
      b: [-21.80, 0, 0],
      c: [22.20, 0, 0],
      pA: [0,-0.4,2.3],
      pB: [0,-0.4,-17.9],
    };
    const standData = this.brands[standType][standName]['data'];
    // @ts-ignore
    const menu = new AFRAME.THREE.Group();
    // Iterar en cada menu item y verificar si existe data
    for (const key in standData) {
      if (standData.hasOwnProperty(key) && standData[key] !== null && standData[key].length > 0) {
        let menuItem = this.createBronceMenuItem( key, standData[key], menu.children.length );
        menu.add(menuItem);
      }
    }
    const xMenu = menu.children.length * 0.3 / 2;
    menu.position.set(-xMenu, 1.35, -2.2);
    // @ts-ignore
    const menuWrap = new AFRAME.THREE.Group();
    menuWrap.position.set(standPositions[standPosition][0], standPositions[standPosition][1], standPositions[standPosition][2]);
    menuWrap.add(menu);
    return menuWrap;
  }

  /**
   * Crear un item en la botonera
   * @param key nombre del parametro e.g video
   * @param standData dato del parametro e.g https://google.com
   * @param menuItems numero de items en el menu para calcular el desplazamiento horizontal
   * @returns 
   */
  createBronceMenuItem(key, standData, menuItems) {
    const itemTextures = {
      video: 'https://firebasestorage.googleapis.com/v0/b/fir-evento-3d-a78b7.appspot.com/o/botones%2FBOTONERA-01.png?alt=media&token=4407511e-019a-4bc0-a025-3c4ef684ac57',
      brochure: "https://firebasestorage.googleapis.com/v0/b/fir-evento-3d-a78b7.appspot.com/o/botones%2FBOTONERA-05.png?alt=media&token=b2562e57-385b-4c2c-8a29-18c4a2362260",
      chat:"https://firebasestorage.googleapis.com/v0/b/fir-evento-3d-a78b7.appspot.com/o/botones%2FBOTONERA-02.png?alt=media&token=545aedef-51c5-4dc1-9ea5-3a3f06bcf48d",
      productos:"https://firebasestorage.googleapis.com/v0/b/fir-evento-3d-a78b7.appspot.com/o/botones%2FBOTONERA-06.png?alt=media&token=f1a44575-a85a-4679-ac15-f385944c6695",
      redes: "https://firebasestorage.googleapis.com/v0/b/fir-evento-3d-a78b7.appspot.com/o/botones%2FBOTONERA-03.png?alt=media&token=ef8b058f-bfd3-4b9c-a096-1ec5d3d35a85",
      servicios:"https://firebasestorage.googleapis.com/v0/b/fir-evento-3d-a78b7.appspot.com/o/botones%2FBOTONERA-07.png?alt=media&token=17649922-333b-4755-8943-b38cb39aa81b",
      website:"https://firebasestorage.googleapis.com/v0/b/fir-evento-3d-a78b7.appspot.com/o/botones%2FBOTONERA-04.png?alt=media&token=d6c48339-f488-4d45-b7c5-a02b08a84c1c",
      whatsapp: "https://firebasestorage.googleapis.com/v0/b/fir-evento-3d-a78b7.appspot.com/o/botones%2FBOTONERA-08.png?alt=media&token=a9cdc0f2-8099-46a2-8e9a-e64c1de5e05d",
      ecommerce: "https://firebasestorage.googleapis.com/v0/b/fir-evento-3d-a78b7.appspot.com/o/botones%2FBOTONERA-09.png?alt=media&token=c6f9645d-b8ce-4757-8a6e-5378523c0ddb"
    };
    // @ts-ignore
    const geometry = new AFRAME.THREE.BoxGeometry(0.3, 0.3, 0.05);
    // @ts-ignore
    const texture = new AFRAME.THREE.TextureLoader().load(itemTextures[key]);
    // @ts-ignore
    const material = new AFRAME.THREE.MeshBasicMaterial({ map: texture });
    // @ts-ignore
    const cube = new AFRAME.THREE.Mesh(geometry, material);
    const xValue = menuItems * 0.3;
    cube.position.set(xValue,0,0);
    cube.userData.link = standData;
    cube.userData.name = key;
    cube.userData.boton = true;
    return cube;
  }

  // Cerrar video popup
  // tslint:disable-next-line:typedef
  setVideoIframeURI(url) {
    this.activeIframeVideo = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  // tslint:disable-next-line:typedef
  closeVideoiframePopup() {
    this.activeIframeVideo = null;
  }

  // tslint:disable-next-line:typedef
  getStandType(type, loadGTLF) {
    return new Promise(resolve => {
      firebase.database().ref('standTypes').child(type).once('value', snap => {
        if (loadGTLF) {
          this.jsonToGltf(snap.val()).then(object => {
            resolve(object);
          });
        }
        else {
          resolve(snap.val());
        }
      });
    });
  }

  // tslint:disable-next-line:typedef
  getBrandsByType(brand) {
    return new Promise(resolve => {
      firebase.database().ref('v2').child('p1').child(brand).once('value', snap => {
        resolve(snap.val());
      }).catch(e => {
        console.log(e);
      });
    });
  }

  // tslint:disable-next-line:typedef
  parseGLTF(data) {
    return new Promise(resolve => {
      const jsonData = JSON.stringify(data);
      // @ts-ignore
      const loader = new AFRAME.THREE.GLTFLoader();
      loader.setCrossOrigin('anonymous');
      loader.parse(jsonData, '', result => {
        const blopStand = new Blob([jsonData], {type: 'model/gltf+json'});
        const object = URL.createObjectURL(blopStand);
        setTimeout(() => {
          resolve(object);
        }, 100);
      });
    });
  }

  // tslint:disable-next-line:typedef
  jsonToGltf(standData) {
    return new Promise(resolve => {
      const jsonData = JSON.stringify(standData);
      this.gltfLoaderInstance.parse(jsonData, '', data => {
        resolve(data.scene.children[0]);
      });
    });
  }

  // tslint:disable-next-line:typedef
  standJsonToGltf(item) {
    return new Promise(resolve => {
      const standData = {...this.types[item.type], images: item.images};
      if (item.type === 'bronce') {
        standData.materials[7] = item.materials.PinkMangueroColor;
        standData.materials[10] = item.materials.YellowMangueroColor;
      } else {
        standData.materials[2] = item.materials.DarkBlueColorInterCapital;
      }
      const jsonData = JSON.stringify(standData);
      this.gltfLoaderInstance.parse(jsonData, '', data => {
        resolve(data.scene.children[0]);
      });
    });
  }

  // tslint:disable-next-line:typedef
  gltfLoader(name) {
    return new Promise(resolve => {
      this.gltfLoaderInstance.load(`../../assets/gltf/gltf/${name}.gltf`, gltf => {
        resolve(gltf.scene.children[0]);
      });
    });
  }

  gltfLoaderGold() {
    return new Promise(resolve => {
      this.gltfLoaderInstance.load(`../../assets/gltf/gltf/fideliscare/FidelisCareS1.gltf`, gltf => {
        resolve(gltf.scene.children[0]);
      });
    });
  }

  // tslint:disable-next-line:typedef
  gltfLoaderHall() {
    return new Promise(resolve => {
      this.gltfLoaderInstance.load('../../assets/hall_salud/ExhibitionHallSmallSalud.gltf', gltf => {
        resolve(gltf.scene.children[0]);
      });
    });
  }

  // Check if is mobile
  isMobile() {
    return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
  } 
  

}


