// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  defaultauth: 'firebase',
  firebaseConfig: {
    /*apiKey: "AIzaSyBuXnh-ZFb2yiU5WHg81V7lGuAw5nmiv4I",
    authDomain: "feriavirtual-3a37d.firebaseapp.com",
    databaseURL: "https://feriavirtual-3a37d-default-rtdb.firebaseio.com",
    projectId: "feriavirtual-3a37d",
    storageBucket: "feriavirtual-3a37d.appspot.com",
    messagingSenderId: "422422447737",
    appId: "1:422422447737:web:ab9be4949299c63685f6a8",
    measurementId: "G-BBK82425C7"*/
    apiKey: "AIzaSyDKnCilRT2J8EKZBBpJhGkkXyYTaX74Pa8",
    authDomain: "fir-evento-3d-a78b7.firebaseapp.com",
    databaseURL: "https://fir-evento-3d-a78b7-default-rtdb.firebaseio.com",
    projectId: "fir-evento-3d-a78b7",
    storageBucket: "fir-evento-3d-a78b7.appspot.com",
    messagingSenderId: "1054906820745",
    appId: "1:1054906820745:web:5e0ea1f3afe591542a7e58",
    measurementId: "G-VJ8BVQEP8J"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
