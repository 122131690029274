import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '../../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../../core/services/authfake.service';
import { getFirebaseBackend } from '../../../authUtils';
import { getCloudFirestore } from '../../../core/firebaseCode/cloudFirestore';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

/**
 * Login component
 */
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;
  error = '';
  returnUrl: string;

  // set the currenr year
  year: number = new Date().getFullYear();

  // tslint:disable-next-line: max-line-length
  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router, public authenticationService: AuthenticationService, public authFackservice: AuthfakeauthenticationService) { }


  ngOnInit(): void {
    // this.router.navigate(['/signup']);
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
    // this.loginForm = this.formBuilder.group({
    //   email: ['', [Validators.required, Validators.email]],
    //   password: ['', [Validators.required]],
    // });
  }
  
  get f() { return this.loginForm.controls; }
  
  onSubmit(): void {
    this.submitted = true;
    
    if (this.loginForm.invalid) {
      return;
    } else {
      if (environment.defaultauth === 'firebase') {
        this.authenticationService.login(this.f.email.value, '123456').then((res: any) => {
          this.router.navigate(['/']);
          localStorage.setItem('idUser', `${getFirebaseBackend().uidUser()}`);
          localStorage.setItem('sesion', 'true');
          getCloudFirestore().getNameUser();
        })
          .catch(error => {
            this.error = error ? error : '';
          });
        // this.authenticationService.login(this.f.email.value, this.f.password.value).then((res: any) => {
        //   this.router.navigate(['/']);
        //   console.log(this.authenticationService.currentUser());
        //   console.log(getFirebaseBackend().uidUser());
        //   localStorage.setItem('idUser', `${getFirebaseBackend().uidUser()}`);
        //   localStorage.setItem('sesion', 'true');
        // })
        //   .catch(error => {
        //     this.error = error ? error : '';
        //   });
      }
    }
  }

  // async onLoginProvider(llave): Promise<any> {
  //   const login = await getCloudFirestore().loginProvider(llave);
  //   console.log(login);
  // }
}
