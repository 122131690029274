import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../core/services/auth.service';
import { getCloudFirestore } from '../core/firebaseCode/cloudFirestore';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  logout = false;
  nameUser = "...";

  constructor(private authService: AuthenticationService, private router: Router) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.nameUser = getCloudFirestore().nameUser;

      if (this.nameUser === "") {
        this.nameUser = localStorage.getItem('nameUser');
      }
    }, 1000);
  }

  logOut(): void {
    localStorage.removeItem('idUser');
    localStorage.removeItem('idChat');
    localStorage.removeItem('nameUser');
    localStorage.removeItem('v');
    localStorage.setItem('sesion', 'false');
    this.authService.logout();
    this.router.navigate(['/login']);
    // this.router.navigate(['/account/login']);
  }

}


