import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-letrero',
  templateUrl: './letrero.component.html',
  styleUrls: ['./letrero.component.scss']
})
export class LetreroComponent implements OnInit {

  windowChatVisible = false;
  chat = false;

  constructor() { }

  ngOnInit(): void {
    // this.widthScreen();
  }

  // widthScreen(): void {
  //   const widthScreen = screen.width;

  //   setTimeout(() => {
  //     const menu = document.getElementById('menu');
  //     const contDescrip = document.getElementById('contDescrip');

  //     if (widthScreen < 450) {
  //       menu.style.width = `${widthScreen}px !important`;
  //       contDescrip.style.width = `${widthScreen}px !important`;
  //     }
  //   }, 50);
  // }

  openWindowChat(): void {
    const widthScreen = screen.width;

    if (!this.windowChatVisible) {
      this.windowChatVisible = true;

      if (widthScreen < 450) {
        const contChat = document.getElementById('contChat');
        contChat.style.width = `${widthScreen}px !important`;
      }
    } else {
      this.windowChatVisible = false;
    }
  }

}
