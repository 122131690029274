import * as firebase from 'firebase/app';
import 'firebase/firestore';

class CloudFirestore {

  users = [];
  messagesChatPrivate = [];
  nameUser = "";

  constructor(firebaseConfig) {
  }

  registrarDatosUsuario(nomb: any, apell: any, provin: any, pais: any, correo: any, pass: any, idDoc: any): void {
      firebase.firestore()
            .collection('users')
            .doc(idDoc)
            .set({
              nombre    : nomb,
              apellido  : apell,
              email     : correo,
              provincia : provin,
              pais      : pais,
              clave     : pass,
              chats     : [],
              rol       : 'general'
            });
  }

  // async loginProvider(llave: any): Promise<any> {
  //   var provider: firebase.auth.AuthProvider;

  //   if (llave == 1) {
  //     provider = new firebase.auth.FacebookAuthProvider();
  //   } else {
  //     provider = new firebase.auth.GoogleAuthProvider();
  //   }

  //   firebase.auth().currentUser.linkWithPopup(provider).then((result) => {
  //     var credential = result.credential;
  //     console.log(credential);
  //     var user = result.user;
  //     console.log(user);
  //   }).catch((error) => {
  //     var errorMessage = error.message;
  //     console.log(errorMessage);
  //   });

  //   // const provider = new firebase.auth.GoogleAuthProvider();

  //   // firebase.auth()
  //   //   .signInWithPopup(provider)
  //   //   .then((result) => {
  //   //     /** @type {firebase.auth.OAuthCredential} */
  //   //     var credential = result.credential;
  //   //     console.log(credential);
  //   //     var user = result.user;
  //   //     console.log(user);
  //   //     // ...
  //   //     return user;
  //   //   }).catch((error) => {
  //   //     var errorMessage = error.message;
  //   //     console.log(errorMessage);
  //   //     return errorMessage;
  //   //   });
  // }

  async getUsuarios(): Promise<any> {
    const idUser = localStorage.getItem('idUser');
    this.users = [];

    firebase.firestore()
            .collection('users')
            .get()
            .then(users => {
              if (!users.empty) {
                users.forEach(user => {
                  if (user.id !== idUser) {
                    this.users.push({
                      id          : `${user.id}`,
                      name        : `${user.data().nombre} ${user.data().apellido}`,
                      lastMessage : 'Este es un mensaje de prueba',
                      time        : 'chat.tabs.chats.recent.userslist.user3.time',
                      idChat      : `${user.id}${idUser}`,
                      idUserAct   : `${idUser}`
                    });
                  }
                });
              }
            });
  }

  getDataUser(): void {
    const idUser = localStorage.getItem('idUser');
    
    firebase.firestore()
            .collection('users')
            .doc(idUser)
            .get()
            .then(data => {
              if (data.exists) {
                const name = data.data().nombre;
                const lastName = data.data().apellido;
                const chats = data.data().chats;
                localStorage.setItem('nameUser', `${name} ${lastName}`);
              }
            });
  }

  getNameUser(): void {
    const idUser = firebase.auth().currentUser.uid;

    firebase.firestore()
            .collection('users')
            .doc(idUser)
            .get()
            .then(doc => {
              console.log(doc);
              if (doc.exists) {
                const nombre = doc.data().nombre;
                const apellido = doc.data().apellido;
                this.nameUser = `${nombre} ${apellido}`;

                localStorage.setItem('nameUser', `${nombre} ${apellido}`);
              }
            });
  }

  async getChatPrivate(idUserTwo): Promise<void> {
    const idUser = localStorage.getItem('idUser');
    const refChat = firebase.firestore()
                            .collection('chats');

    firebase.firestore()
            .collection('users')
            .doc(idUser)
            .get()
            .then(data => {
              if (data.exists) {
                const chats = data.data().chats;
                // console.log(chats);
                let contador = 0;
                if (chats.length !== 0) {
                  for (const chatIden of chats) {
                    contador++;
                    // console.log(contador);
                    if (chatIden.idUserChat === idUserTwo) {
                      // console.log('Encontro el chat con id => ' + chatIden.idUserChat);
                      // console.log('Encontro el chat con id => ' + chatIden.idChat);
                      localStorage.setItem('idChat', chatIden.idChat);
                      const idChat = localStorage.getItem('idChat');

                      refChat.doc(idChat)
                              .onSnapshot(chat => {
                                if (chat.exists) {
                                  document.getElementById('contMSJChat').innerHTML = '';
                                  const messages = chat.data().messages;

                                  if (messages.length === 0) {
                                    document.getElementById('contMSJChat').innerHTML = '';
                                    let msjIniciarChat = '';
                                    msjIniciarChat = `<div class="chat-day-title">
                                                                <span class="title">Iniciar chat</span>
                                                              </div>`;
                                    document.getElementById('contMSJChat').innerHTML = msjIniciarChat;
                                  } else {
                                    for (const mssg of messages) {
                                      const liChat = document.createElement('li');

                                      if (mssg.id === idUser) {
                                        liChat.setAttribute('class', 'right');
                                      }

                                      const date = new Date(mssg.time);
                                      const hora = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

                                      liChat.innerHTML = `<div class="conversation-list">
                                                            <div class="user-chat-content">
                                                              <div class="ctext-wrap">
                                                                <div class="ctext-wrap-content">
                                                                  <p class="mb-0">
                                                                    ${mssg.message}
                                                                  </p>
                                                                  <p class="chat-time mb-0">
                                                                    <i class="ri-time-line align-middle"></i>
                                                                    <span class="align-middle">${hora}</span>
                                                                  </p>
                                                                </div>
                                                              </div>
                                                              <div class="conversation-name">${mssg.name}</div>
                                                            </div>
                                                          </div>`;
                                      document.getElementById('contMSJChat').appendChild(liChat);
                                    }
                                  }
                                } else {
                                  // this.createChatPrivate(idChat, nombre);
                                }
                              });
                      break;
                    } else if (contador === chats.length) {
                      // console.log('Crear nuevo chat');
                      this.createChatPrivate(idUser, idUserTwo);
                      break;
                    }
                  }
                } else {
                  // console.log('No hay chat');
                  this.createChatPrivate(idUser, idUserTwo);
                }
              }
            });
  }

  getChatEnVivo(): void {
    const idUser = localStorage.getItem('idUser');
    const idChat = localStorage.getItem('idChat');

    firebase.firestore()
            .collection('chats')
            .doc(idChat)
            .onSnapshot(chat => {
              const idChatOn = localStorage.getItem('idChat');
              if (chat.exists && idChatOn === 'chatEnVivoGrupal') {
                if (document.getElementById('contMSJChat')) {
                  document.getElementById('contMSJChat').innerHTML = '';
                }
                const messages = chat.data().messages;

                if (messages.length === 0) {
                  document.getElementById('contMSJChat').innerHTML = '';
                  let msjIniciarChat = '';
                  msjIniciarChat = `<div class="chat-day-title">
                                              <span class="title">Iniciar chat en vivo</span>
                                            </div>`;
                  document.getElementById('contMSJChat').innerHTML = msjIniciarChat;
                } else {
                  for (const mssg of messages) {
                    const liChat = document.createElement('li');

                    if (mssg.id === idUser) {
                      liChat.setAttribute('class', 'right');
                    }

                    const date = new Date(mssg.time);
                    const hora = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

                    liChat.innerHTML = `<div class="conversation-list">
                                          <div class="user-chat-content">
                                            <div class="ctext-wrap">
                                              <div class="ctext-wrap-content">
                                                <p class="mb-0">
                                                  ${mssg.message}
                                                </p>
                                                <p class="chat-time mb-0">
                                                  <i class="ri-time-line align-middle"></i>
                                                  <span class="align-middle">${hora}</span>
                                                </p>
                                              </div>
                                            </div>
                                            <div class="conversation-name">${mssg.name}</div>
                                          </div>
                                        </div>`;
                    if (document.getElementById('contMSJChat')) {
                      document.getElementById('contMSJChat').appendChild(liChat);
                    }
                  }
                }
              } else {
                // this.createChatPrivate(idChat, nombre);
              }
            });
  }

  createChatPrivate(idUser: any, idUserTwo: any): void {
    const refBDUser = firebase.firestore()
                              .collection('users')
                              .doc(idUser);
    const refBDUserTwo = firebase.firestore()
                              .collection('users')
                              .doc(idUserTwo);

    firebase.firestore()
            .collection('chats')
            .add({
              messages : []
            })
            .then(docRef => {
              const chatUser = {
                idUserChat : idUserTwo,
                idChat     : docRef.id
              };
              const chatUserTwo = {
                idUserChat : idUser,
                idChat     : docRef.id
              };

              refBDUser.get()
                        .then(data => {
                          if (data.exists) {
                            const chatsBD = data.data().chats;
                            chatsBD.push(chatUser);

                            refBDUser.update({
                              chats : chatsBD
                            })
                            .then(() => {
                              // console.log('hecho 1');
                            });
                          }
                        });

              refBDUserTwo.get()
                          .then(data => {
                            if (data.exists) {
                              const chatsBD = data.data().chats;
                              chatsBD.push(chatUserTwo);

                              refBDUserTwo.update({
                                chats : chatsBD
                              })
                              .then(() => {
                                // console.log('hecho 2');
                              });
                            }
                          });

              document.getElementById('contMSJChat').innerHTML = '';
              const msjIniciarChat = `<div class="chat-day-title">
                                        <span class="title">Iniciar chat</span>
                                      </div>`;
              document.getElementById('contMSJChat').innerHTML = msjIniciarChat;
            });
  }

  setNewMessage(mess): void {
    const idUser = localStorage.getItem('idUser');
    const nameUser = localStorage.getItem('nameUser');
    const idChat = localStorage.getItem('idChat');
    const date = new Date();
    const newMessage = {
      id: idUser,
      message: mess,
      name: nameUser,
      time: date.getTime()
    };
    const refDB = firebase.firestore()
                          .collection('chats')
                          .doc(idChat);
    refDB.get()
          .then(chat => {
            if (chat.exists) {
              const messagesChat = chat.data().messages;
              messagesChat.push(newMessage);

              refDB.set({
                messages: messagesChat
              })
              .then(() => {
                // this.getChatPrivate(idChat, nameUser);
                this.setMensajeSinLeer();
              });
            }
          });
  }

  setMensajeSinLeer() {
    const userChat = localStorage.getItem('userChat');
    const idUser = localStorage.getItem('idUser');
    const refBDUser = firebase.firestore()
      .collection('users')
      .doc(idUser);

    refBDUser.get()
      .then(data => {
        if (data.exists) {
          const chatsBD = data.data().chats;
          var contador = 0;

          chatsBD.forEach(chat => {
            if (chat.idUserChat == userChat) {
              const chatUser = {
                idUserChat : chat.idUserChat,
                idChat     : chat.idChat,
                sinLeer    : true
              };
              chatsBD[contador] = chatUser;

              refBDUser.update({
                chats: chatsBD
              })
              .then(() => {
                // console.log(chatsBD);
              });
            }
            contador++;
          });
        }
      });
  }

}

let fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 */

const initCloudFirestore = (config) => {
    if (!fireBaseBackend) {
      fireBaseBackend = new CloudFirestore(config);
    }
    return fireBaseBackend;
};

const getCloudFirestore = () => {
    return fireBaseBackend;
};

export { initCloudFirestore, getCloudFirestore };
