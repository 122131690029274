<div class="fondo">
    <div class="account-pages my-3 pt-sm-3" id="login">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-8 col-lg-6 col-xl-5">
                    <div class="text-center mb-4">
                        <a class="auth-logo mb-2 d-block">
                            <!--../../../../assets/images/BOTONES_Y_FONDO_NUEVO_LOBBY/FONDO_REGISTRO/LOGO.png-->
                            <img src="" alt="" height="220" class="logo logo-dark">
                        </a>

                        <!-- <h4>{{'login.title' | translate}}</h4> -->
                        <!-- <h4>Iniciar Sesión</h4> -->
                        <!-- <p class="text-muted mb-4">{{'login.message' | translate}}</p> -->

                    </div>

                    <div class="card">
                        <div class="card-body p-4">
                            <div class="p-3">
                                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                                    <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>

                                    <div class="form-group">
                                        <label>{{'login.form.email.label' | translate}}</label>
                                        <div class="input-group mb-3 bg-soft-light input-group-lg rounded-lg">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text border-light text-muted">
                        <i class="ri-user-2-line"></i>
                      </span>
                                            </div>
                                            <input type="email" class="form-control bg-soft-light border-light" placeholder="{{'login.form.email.placeholder' | translate}}" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                <div *ngIf="f.email.errors.required">{{'login.form.email.validation.required' | translate}}</div>
                                                <div *ngIf="f.email.errors.email">{{ 'login.form.email.validation.invalid' | translate}}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <div class="form-group mb-4">
                  Desde aca
                  <div class="float-right">
                    <a routerLink="/account/reset-password"
                      class="text-muted font-size-13">{{'login.form.forgotlink' | translate}}</a>
                  </div>
                  hasta aca estaba comentarizado
                  <label>{{'login.form.password.label' | translate}}</label>
                  <div class="input-group mb-3 bg-soft-light input-group-lg rounded-lg">
                    <div class="input-group-prepend">
                      <span class="input-group-text border-light text-muted">
                        <i class="ri-lock-2-line"></i>
                      </span>
                    </div>
                    <input type="password" formControlName="password" class="form-control bg-soft-light border-light"
                      placeholder="{{'login.form.password.placeholder' | translate}}"
                      [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                      <div *ngIf="f.password.errors.required">{{'login.form.password.validation.required' | translate}}
                      </div>
                    </div>
                  </div>
                </div> -->

                                    <!-- <div class="custom-control custom-checkbox mb-4">
                  <input type="checkbox" class="custom-control-input" id="remember-check">
                  <label class="custom-control-label" for="remember-check">{{'login.form.remebertext' | translate}}</label>
                </div> -->

                                    <div>
                                        <button class="btn nackgroudBtn btn-block" type="submit">LogIn</button>
                                    </div>
                                    <!-- <div class="contBtnRedes">
                  <button class="btnRedes" (click)="onLoginProvider(1)"><img src="../../../../assets/images/iconFacebook.webp"></button>
                  <button class="btnRedes" style="margin-left: 10px;" (click)="onLoginProvider(2)"><img src="../../../../assets/images/iconGoogle.png"></button>
                </div> -->
                                </form>

                                <div class="mt-1 text-center">
                                    <p>{{'login.signuptext' | translate}} <a routerLink="/signup" class="font-weight-medium colorTexto">
                  {{'login.signuplink' | translate}}
                </a>
                                    </p>
                                    <!-- <p>© {{year}} {{'footer.name' | translate}} <i class="mdi mdi-heart text-danger"></i>
                {{'footer.text' | translate}}</p> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="card-container">
                        <div class="colorear">
                            <a class="circle-link" title="Animations" href="https://angular.io/guide/animations" target="_blank" rel="noopener">

                                <img width="150.81" height="50.447" src="../../../../assets/images/LOGOS_INFERIORES.png" alt="">

                            </a>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>