import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '../../../core/services/auth.service';
import { UserProfileService } from '../../../core/services/user.service';
import { getFirebaseBackend } from '../../../authUtils';
import { getCloudFirestore } from '../../../core/firebaseCode/cloudFirestore';

import { environment } from '../../../../environments/environment';
// import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';

declare var paypal;

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})

export class SignupComponent implements OnInit {

  signupForm: FormGroup;
  submitted = false;
  error = '';
  successmsg = false;
  errorPagoPaypal = false;
  btnPaypalVisible = 'hidden';
  modalRegistoExitoso = 'hidden';
  visibleForm = true;
  emailRegister = "";
  
  year: number = new Date().getFullYear();

  // public payPalConfig: IPayPalConfig;
  
  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService,
              private userService: UserProfileService) {
                // this.initConfig();
              }

  @ViewChild('paypal', { static: true }) paypalElement: ElementRef;

  ngOnInit(): void {
    this.signupForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      apellido: ['', Validators.required],
      provincia : ['', Validators.required],
      pais: ['', Validators.required],
      username: ['', Validators.required],
    });

    paypal
    .Buttons({
      createOrder: (data, actions) => {
        return actions.order.create({
          purchase_units : [{
            description  : 'Donación',
            amount       : {
              currency_code : 'USD',
              value         : 0.1
            }
          }]
        });
      },
      onApprove: async (data, actions) => {
        const order = await actions.order.capture();
        console.log(order);

        // if (order.status === 'COMPLETED') {
        //   this.btnPaypalVisible = 'hidden';
        //   this.visibleForm = true;
        // }
      },
      onError: err => {
        console.log(err);
        this.errorPagoPaypal = true;
      }
    })
    .render( this.paypalElement.nativeElement );
  }
  
  get f(): any { return this.signupForm.controls; }
  
  onSubmit(): void {
    this.submitted = true;
    
    if (this.signupForm.invalid) {
      return;
    } else {
      if (environment.defaultauth === 'firebase') {
        this.authenticationService.register(this.f.email.value, '123456').then((res: any) => {
          this.successmsg = true;
          if (this.successmsg) {
            console.log(this.authenticationService.currentUser());
            console.log(getFirebaseBackend().uidUser());
            localStorage.setItem('idUser', `${getFirebaseBackend().uidUser()}`);
            localStorage.setItem('sesion', 'true');
            getCloudFirestore().getNameUser();

            const idDoc = getFirebaseBackend().uidUser();
            getCloudFirestore().registrarDatosUsuario(this.f.username.value,
                                                      this.f.apellido.value,
                                                      this.f.provincia.value,
                                                      this.f.pais.value,
                                                      this.f.email.value,
                                                      '123456',
                                                      idDoc);
            // this.modalRegistoExitoso = 'visible';
            this.visibleForm = false;
            this.emailRegister = this.f.email.value;
            this.router.navigate(['/']);
          }
        })
          .catch(error => {
            this.error = error ? error : '';
          });
      } else {
        this.userService.register(this.signupForm.value)
          .pipe(first())
          .subscribe(
            data => {
              this.successmsg = true;
              if (this.successmsg) {
                this.router.navigate(['/login']);
              }
            },
            error => {
              this.error = error ? error : '';
            });
      }
    }
  }

  // initConfig() {
  //   this.payPalConfig = {
  //     currency: 'USD',
  //     clientId: 'ASiNzQ1WmiOYpTOdrmyBskYW6_lOid09x-oPEN0ryIqQnx3KRZ4P8Xx3A8QNwUrUQaSv9J4mkjw4NmXM',

  //     createOrderOnClient: (data) => <ICreateOrderRequest>{
  //       intent: 'CAPTURE',
  //       purchase_units: [
  //         {
  //           amount: {
  //             currency_code: 'USD',
  //             value: '0.1',
  //             breakdown: {
  //               item_total: {
  //                 currency_code: 'USD',
  //                 value: '0.1'
  //               }
  //             }
  //           },
  //           items: [
  //             {
  //               name: 'Donación',
  //               quantity: '1',
  //               category: 'DIGITAL_GOODS',
  //               unit_amount: {
  //                 currency_code: 'USD',
  //                 value: '0.1',
  //               },
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     advanced: {
  //       commit: 'true'
  //     },
  //     style: {
  //       label: 'paypal',
  //       layout: 'vertical'
  //     },
  //     onApprove: (data, actions) => {
  //       console.log('onApprove - transaction was approved, but not authorized', data, actions);
  //       actions.order.get().then(details => {
  //         console.log('onApprove - you can get full order details inside onApprove: ', details);
  //       });
  //     },
  //     onClientAuthorization: (data) => {
  //       console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
  //     },
  //     onCancel: (data, actions) => {
  //       console.log('OnCancel', data, actions);
  //     },
  //     onError: err => {
  //       console.log('OnError', err);
  //     },
  //     onClick: (data, actions) => {
  //       console.log('onClick', data, actions);
  //     },
  //   };
  // }
}
