<div class="card-container ocultar-cell ">

    <div class="mover ocultar-cell">
        <!-- ************************************ Icono Programa o Agenda ************************************ -->
        <!-- <a class="circle-link" title="CLI" href="https://cli.angular.io/" target="_blank" rel="noopener">
    
    <img class="tamaño"  src="../../assets/images/MENU/agenda.png" alt="">

  </a> -->
        <!-- ************************************ Icono Pabellón 1 ************************************ -->
        <a class="circle-link" [routerLink]="[ 'Pabellon1' ]" rel="noopener" title="HALL l">

            <img class="tamaño" id="icon-pabellon" src="../../assets/images/MENU/pabellon.png" alt="">
        </a>
        <!-- ************************************ Icono Pabellón 2 ************************************ -->
        <!--<a class="circle-link" [routerLink]="[ 'pabellon2' ]" rel="noopener" title="HALL 2">

            <img class="tamaño" id="icon-pabellon" src="../../assets/images/MENU/pabellon2.png" alt="">
        </a>-->
        <!-- ************************************ Icono EnVivo ************************************ -->
        <a class="circle-link" title="LIVE" href="#" target="_blank" rel="noopener">

            <img class="tamaño" id="icon-envivo" src="../../assets/images/MENU SEÑALIZACION/MENU_LATERALES_VIVO.png" alt="">

        </a>


        <!-- <a class="circle-link" title="Find a Local Meetup" href="https://www.meetup.com/find/?keywords=angular" target="_blank" rel="noopener">
    
    <img class="tamaño"  src="../../assets/images/MENU/pabellon3.png" alt="">
  
  </a> -->
        <!-- ************************************ Icono Programa ************************************ -->
        <a class="circle-link" title="PROGRAM" href="#" target="_blank" rel="noopener">

            <img class="tamaño" id="icon-programa" src="../../assets/images/MENU SEÑALIZACION/MENU_PROGRAMACION.png" alt="">

        </a>
        <!-- ************************************ Icono Sponsor ************************************ -->
        <a class="circle-link" title="SPONSOR" href="#" target="_blank" rel="noopener">

            <img class="tamaño" id="icon-sponsor" src="../../assets/images/MENU SEÑALIZACION/MENU_SPOSORS.png" alt="">

        </a>
        <!-- ************************************ Icono Chat ************************************ -->
        <a class="circle-link" title="CHAT" (click)="openWindowChat()" rel="noopener">

            <img class="tamaño" id="icon-chat" src="../../assets/images/MENU SEÑALIZACION/MENU_CHAT.png" alt="">

        </a>

    </div>


</div>
<div class="contSecundario">
    <div id="contChat" class="contChat" *ngIf="windowChatVisible">
        <app-index></app-index>
    </div>
</div>