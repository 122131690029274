<div class="fondo">
    <div class="account-pages my-2 pt-sm-2">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-10 col-lg-7 col-xl-7">
                    <div class="text-center mb-4">
                        <a class="auth-logo mb-2 d-block">
                            <img src="" alt="" height="110" class="logo logo-dark">
                        </a>

                        <!--<h4>{{'signup.title' | translate}}</h4>-->
                        <!-- <p class="text-muted mb-4">{{'signup.message' | translate}}</p> -->
                    </div>

                    <!-- <ngx-paypal [config]="payPalConfig"></ngx-paypal> -->
                     <!--<div class="card" [ngClass]="btnPaypalVisible">
                        <ngb-alert type="danger" *ngIf="errorPagoPaypal" [dismissible]="false">Algo salio mal, por favor intenta de nuevo en un momento.</ngb-alert>
                        <div class="card-body p-2">
                            <p class="textoBtnPaypal">Para continuar con le registro te pedimos que hagas una pequeña donación de 5 dólares.</p>
                            <div #paypal></div>
                        </div>
                    </div>

                   <div class="card" [ngClass]="modalRegistoExitoso">
                        <div class="card-body p-2">
                            <p class="tituloConfirmacionRegistro">¡Gracias por registrarse Al Dominican Taste Festival!</p>
                            <p class="textoConfirmacionRegistro">El 24 y 25 de Julio Recibirá un recordartorio para ingresar a la plataforma virtual usando el mismo email con el que se registro</p>
                            <p class="textoConfirmacionRegistro">Este es el email registrado, con el cual deberan ingresar el día del evento:</p>
                            <p class="textoConfirmacionRegistroNegrilla interlineado">{{emailRegister}}</p><br>
                            <p class="textoConfirmacionRegistro">Se ha enviado un correo electrónico de confirmación con su registro exitoso.</p>
                            <p class="textoConfirmacionRegistroPlease interlineado">!Por favor revise también en la carpeta del spam¡</p>
                        </div>
                    </div>-->

                    <div class="card" *ngIf="visibleForm">
                        <div class="card-body p-2">
                            <div class="p-3">
                                <form [formGroup]="signupForm" (ngSubmit)="onSubmit()">
                                    <ngb-alert type="success" *ngIf="successmsg" [dismissible]="false">Registeration successfull.
                                    </ngb-alert>
                                    <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>

                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label>{{'signup.form.username.label' | translate}}</label>
                                                <div class="input-group mb-3 bg-soft-light input-group-lg rounded-lg">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text border-light text-muted">
                              <i class="ri-user-2-line"></i>
                            </span>
                                                    </div>
                                                    <input type="text" class="form-control bg-soft-light border-light" placeholder="{{'signup.form.username.placeholder' | translate}}" formControlName="username" [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
                                                    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                                        <div *ngIf="f.username.errors.required">
                                                            {{'signup.form.username.validation.required' | translate}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label>{{'signup.form.apellido.label' | translate}}</label>
                                                <div class="input-group mb-3 bg-soft-light input-group-lg rounded-lg">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text border-light text-muted">
                              <i class="ri-user-2-line"></i>
                            </span>
                                                    </div>
                                                    <input type="text" class="form-control bg-soft-light border-light" placeholder="{{'signup.form.apellido.placeholder' | translate}}" formControlName="apellido" [ngClass]="{ 'is-invalid': submitted && f.apellido.errors }">
                                                    <div *ngIf="submitted && f.apellido.errors" class="invalid-feedback">
                                                        <div *ngIf="f.apellido.errors.required">
                                                            {{'signup.form.apellido.validation.required' | translate}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label>{{'signup.form.provincia.label' | translate}}</label>
                                                <div class="input-group mb-3 bg-soft-light input-group-lg rounded-lg">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text border-light text-muted">
                              <i class="ri-map-pin-line"></i>
                            </span>
                                                    </div>
                                                    <input type="text" class="form-control bg-soft-light border-light" placeholder="{{'signup.form.provincia.placeholder' | translate}}" formControlName="provincia" [ngClass]="{ 'is-invalid': submitted && f.provincia.errors }">
                                                    <div *ngIf="submitted && f.provincia.errors" class="invalid-feedback">
                                                        <div *ngIf="f.provincia.errors.required">
                                                            {{'signup.form.provincia.validation.required' | translate}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label>{{'signup.form.pais.label' | translate}}</label>
                                                <div class="input-group mb-3 bg-soft-light input-group-lg rounded-lg">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text border-light text-muted">
                              <i class="ri-map-pin-line"></i>
                            </span>
                                                    </div>
                                                    <input type="text" class="form-control bg-soft-light border-light" placeholder="{{'signup.form.pais.placeholder' | translate}}" formControlName="pais" [ngClass]="{ 'is-invalid': submitted && f.pais.errors }">
                                                    <div *ngIf="submitted && f.pais.errors" class="invalid-feedback">
                                                        <div *ngIf="f.pais.errors.required">
                                                            {{'signup.form.pais.validation.required' | translate}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label>{{'signup.form.email.label' | translate}}</label>
                                        <div class="input-group mb-3 bg-soft-light input-group-lg rounded-lg">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text border-light text-muted">
                          <i class="ri-mail-line"></i>
                        </span>
                                            </div>
                                            <input type="email" formControlName="email" class="form-control bg-soft-light border-light" placeholder="{{'signup.form.email.placeholder' | translate }}" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                <div *ngIf="f.email.errors.required">{{ 'signup.form.email.validation.required' | translate}}
                                                </div>
                                                <div *ngIf="f.email.errors.email">{{ 'signup.form.email.validation.invalid' | translate}}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <button class="btn nackgroudBtn btn-block" type="submit">{{'signup.form.button.text' | translate}}</button>
                                    </div>
                                    <!-- <div class="contBtnRedes">
                    <button class="btnRedes"><img src="../../../../assets/images/iconFacebook.webp"></button>
                    <button class="btnRedes" style="margin-left: 10px;"><img src="../../../../assets/images/iconGoogle.png"></button>
                  </div> -->

                                    <!-- <div class="mt-4 text-center">
                    <p class="text-muted mb-0">{{ 'signup.form.notice' | translate}} <a href="#"
                        class="text-primary">{{ 'signup.form.term-text' | translate}}</a></p>
                  </div> -->

                                </form>
                                <div class="mt-1 text-center">
                                    <p>{{'signup.signintext' | translate}} <a routerLink="/login" class="font-weight-medium colorTexto">
                        Iniciar Sesión
                      </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>