<div class="toolbar ver-pc" role="banner">
    <!-- ************************************ Icono home ************************************ -->
    <a class="logo-rd" href="#">
        <img id="logo-ico" width="190" alt="Logo Business Fair" src="../../assets/images/MENU SUPERIOR/LOGO-FERIA-DEMO.png" />
    </a>

    <!-- ************************************ Icono Twitter ************************************ -->
    <div class="spacer"></div>
    <a aria-label="Angular on twitter" target="_blank" rel="noopener" href="#" title="Twitter">
        <img id="twitter-logo" width="20" src="../../assets/images/img-toolbar/Twitter.png" alt="">
    </a>
    <!-- ************************************ Icono Instagram ************************************ -->
    <a aria-label="Angular on YouTube" target="_blank" rel="noopener" href="#" title="Instagram">
        <img id="insta-logo" width="20" src="../../assets/images/img-toolbar/Instagram.png" alt="">
    </a>
    <!-- ************************************ Icono WebSite ************************************ -->
    <a aria-label="Angular on YouTube" target="_blank" rel="noopener" href="#" title="Website">
        <img id="web-logo" width="20" src="../../assets/images/img-toolbar/Website.png" alt="">
    </a>
    <!-- ************************************ Icono facebook ************************************ -->
    <a aria-label="Angular on YouTube" target="_blank" rel="noopener" href="#" title="Facebook">
        <img id="face-logo" width="20" src="../../assets/images/img-toolbar/Facebook.png" alt="">
    </a>

    <div id="logout" class="flex-lg-column d-lg-block" (click)="logOut()" (mouseover)="logout = true" (mouseleave)="logout = false">
        <ul class="nav side-menu-nav justify-content-center">
            <li class="nav-item" ngbTooltip="logout">
                <a class="nav-link" id="pills-chat-tab" href="javascript: void(0);">
                    <i class="ri-logout-circle-r-line colorIcono white"></i>
                </a>
            </li>
        </ul>
    </div>


</div>

<!-- // menu celular -->

<!-- <div class="absoluta">

    <nav class="navbar navbar-expand-lg navbar-dark color-cell ver-cell">
        <a class="navbar-brand" href="#"> <img width="50" alt="Dominican Taste Festival Logo" src="../../assets/images/img-toolbar/LOGO_RD_TASTE_FESTIVAL.png" /> </a>
        <button class="navbar-toggler custom-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon">  
     </span>
  </button>
        <div class="collapse navbar-collapse fondo-des" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">

                <li class="nav-item active">


                    <div class="spacer-cell">
                         ************************************ Icono Twitter ************************************ 
                        <a class="mover-logos" aria-label="Angular on twitter" target="_blank" rel="noopener" href="https://twitter.com/elidaalmontev" title="Twitter Dominican Taste Festival">
                            <img id="twitter-logo" width="20" src="../../assets/images/img-toolbar/Twitter.png" alt="">
                        </a>
                         ************************************ Icono Instagram ************************************ 
                        <a class="mover-logos" aria-label="Angular on YouTube" target="_blank" rel="noopener" href="https://youtube.com/angular" title="Instagram">
                            <img id="insta-logo" width="20" src="../../assets/images/img-toolbar/Instagram.png" alt="">
                        </a>
                       ************************************ Icono WebSite ************************************ 
                        <a class="mover-logos" aria-label="Angular on YouTube" target="_blank" rel="noopener" href="https://facebook.com/angular" title="Website">
                            <img id="web-logo" width="20" src="../../assets/images/img-toolbar/Website.png" alt="">
                        </a>
                         ************************************ Icono facebook ************************************ 
                        <a class="mover-logos" aria-label="Angular on YouTube" target="_blank" rel="noopener" href="https://youtube.com/angular" title="Facebook">
                            <img id="face-logo" width="20" src="../../assets/images/img-toolbar/Facebook.png" alt="">
                        </a>

                    </div>

                </li>


       <li class="nav-item spacer-cell">
                    <a class="nav-link p" [routerLink]="[ '' ]">  Inicio  </a>
                </li>

                <li class="nav-item spacer-cell">
                    <a class="nav-link p" [routerLink]="[ '../Pabellon1' ]"> Pabellon 1 </a>
                </li>

                <li class="nav-item spacer-cell">
                    ************************************ Icono Programa o Agenda ************************************
                    <a class="circle-link" href="https://dominicantastefestival.com/documents/AGENDA_DOMINICAN_TASTE_FESTIVAL.pdf" target="_blank" rel="noopener" title="Programa Dominican Taste Festival">Programa Dominican Taste Festival</a>
                </li>

                <li class="nav-item spacer-cell">
                    ************************************ Icono EnVivo ************************************
                    <a class="circle-link" href="https://www.facebook.com/Dominican-USA-Chamber-of-Commerce-Dusaccom-485323451627688" target="_blank" rel="noopener">EnVivo Dominican Taste Festival
                     </a>
                </li>
                <li class="nav-item spacer-cell">
                    ************************************ Icono Sponsor ************************************
                    <a class="circle-link" title="Sponsor Dominican Taste Festival" href="https://dominicantastefestival.com/documents/SPONSORS.jpg" target="_blank" rel="noopener">Sponsor's Dominican Taste Festival
                    </a>
                </li> 


            </ul>
        </div>
    </nav>
</div> -->