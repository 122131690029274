<div class="fondo">
    <!-- <app-loader></app-loader>VjF7KhlBLfM -->
    <div class="contVideo" *ngIf="videoVisible">
        <!--<youtube-player videoId="7ahFOHWXq_0" class="video" (ready)="onReady($event)" (stateChange)="onStateChange($event)" width="100%" height="100%">
        </youtube-player>-->
    </div>
    <!--<app-video></app-video>-->
    <app-toolbar></app-toolbar>
    <app-letrero></app-letrero>
    <app-menu-inferior></app-menu-inferior>
</div>