<!-- <div class="mainUI" id="uiDiv" oncontextmenu="return false;">

    top-left
    <div class="regionUI skyColor" style="top: 10px; left: 10px;" oncontextmenu="return false;">
    </div>

    top-right
    <div class="regionUI" style="top: 10px; right: 10px;">
    </div>

    bottom-left
    <div class="regionUI" style="bottom: 10px; right: 10px;">
        <div class="buttonUI" style="width: 128px; opacity:0.80;">
            <img src="../../assets/images/joystick-base.png" />
            <div id="stick1" style="position: absolute; right:32px; top:32px;">
                <img src="../../assets/images/joystick-red.png" />
            </div>
        </div>
    </div>

    bottom-right
    <div class="regionUI baseColor" style="bottom: 10px; right: 10px;">
    </div>

</div> -->


<div>
    <!--Se crea la escena y se agrega el componente joystick-->
    <!-- <a-scene joystick vr-mode-ui="enabled: false" raycaster="far: 100; objects: [link];" cursor="rayOrigin: mouse" -->
    <a-scene joystick vr-mode-ui="enabled: false" check-scene-has-loaded *ngIf="loaded" renderer="antialias: true;
        logarithmicDepthBuffer: true;
        physicallyCorrectLights: true;">
        <a-camera id="camera" far="1200" fov="50" look-controls wasd-controls="acceleration:40" position="0 1.6 0" *ngIf="!isMobile()"></a-camera>
        <a-entity camera="far: 10000; fov: 100;" id="camera" look-controls="magicWindowTrackingEnabled: false" wasd-controls position="0 1.6 0" *ngIf="isMobile()"></a-entity>
        <a-entity light="type: hemisphere; color: #fff; groundColor: #fff; intensity: 2"></a-entity>
        <a-entity light="type: directional; color: #EEE; intensity: 2" position="-1 1 0"></a-entity>
        <a-entity feria></a-entity>
        <a-sky src="../../assets/images/CasualDay4K.jpg" rotation="0 -130 0"></a-sky>
        <a-entity id="mouseCursor" cursor="rayOrigin: mouse" raycaster="objects: [raycastable]; recursive: false"></a-entity>
    </a-scene>

    <div id="contChat" class="contChat" *ngIf="windowChatVisible">
        <!-- Todo: integrar chat -->
        <!--<app-index></app-index>-->
    </div>

    <div class="stand-video-iframe" *ngIf="activeIframeVideo">
        <div class="stand-video-iframe-overlay" (click)="closeVideoiframePopup()"></div>
        <div class="stand-video-iframe-wrap">
            <iframe [src]="activeIframeVideo" width="100%" height="100%" frameborder="0" scrolling="auto" allowfullscreen="allowfullscreen"></iframe>
        </div>
    </div>

    <div class="pabellon-loading" *ngIf="!sceneLoaded">
        <div class="pabellon-loading-wrap">
            <div class="loadingio-spinner-ellipsis-nqa08z1w5sj">
                <div class="ldio-j9k9oee186d">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    </div>

</div>
<app-toolbar></app-toolbar>
<app-menu-left></app-menu-left>
<app-menu-inferior></app-menu-inferior>