import { RouterModule, Routes} from '@angular/router';
import { PrincipalComponent } from './principal/principal.component';
import { AframeGala3dComponent } from './aframe-gala3d/aframe-gala3d.component';
import { Pabellon2Component } from './pabellon2/pabellon2.component';


// tslint:disable-next-line:variable-name
const app_routes: Routes = [
    { path: '', component: PrincipalComponent  },
    { path: 'Pabellon1', component: AframeGala3dComponent  },
    { path: 'pabellon2', component: Pabellon2Component  },
    { path: '**', pathMatch: 'full', redirectTo: '' },
];

// tslint:disable-next-line:variable-name
export const app_routing = RouterModule.forRoot(app_routes);
