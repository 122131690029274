 <!-- Start Settings content -->
 <div>
   <div class="px-4 pt-4">
     <h4 class="mb-0">{{'chat.tabs.settings.title' | translate}}</h4>
   </div>

   <div class="text-center border-bottom p-4">
     <div class="mb-4 profile-user">
       <img src="assets/images/users/avatar-1.jpg" class="rounded-circle avatar-lg img-thumbnail" alt="">
       <button type="button" class="btn bg-light avatar-xs p-0 rounded-circle profile-photo-edit shadow-none">
         <i class="ri-pencil-fill"></i>
       </button>
     </div>

     <h5 class="font-size-16 mb-1 text-truncate">{{'chat.tabs.settings.name' | translate}}</h5>
     <div class="dropdown d-inline-block mb-1" ngbDropdown>
       <a class="text-muted dropdown-toggle pb-1 d-block" href="javascript: void(0);" role="button" data-toggle="dropdown"
         aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
         {{'chat.tabs.settings.status.text' | translate}} <i class="mdi mdi-chevron-down"></i>
       </a>

       <div class="dropdown-menu" ngbDropdownMenu>
         <a class="dropdown-item" href="#">{{'chat.tabs.settings.status.dropdown.available' | translate}}</a>
         <a class="dropdown-item" href="#">{{'chat.tabs.settings.status.dropdown.busy' | translate}}</a>
       </div>
     </div>
   </div>
   <!-- End profile user -->

   <!-- Start User profile description -->
   <perfect-scrollbar class="p-4 user-profile-desc" data-simplebar>
     <ngb-accordion #acc="ngbAccordion" activeIds="perfonal-info" [closeOthers]="true">
       <ngb-panel cardClass="card shadow-none border mb-2" id="perfonal-info">
         <ng-template ngbPanelTitle>
           <h5 class="font-size-14 m-0">
             {{'chat.tabs.settings.info.title' | translate}}
           </h5>
         </ng-template>
         <ng-template ngbPanelContent>
           <div class="float-right">
             <button type="button" class="btn btn-light btn-sm shadow-none"><i class="ri-edit-fill mr-1 align-middle"></i>
               {{'chat.tabs.settings.info.edit' | translate}}</button>
           </div>
           <div>
             <p class="text-muted mb-1">{{'chat.tabs.settings.info.name.text' | translate}}</p>
             <h5 class="font-size-14">{{'chat.tabs.settings.info.name.value' | translate}}</h5>
           </div>

           <div class="mt-4">
             <p class="text-muted mb-1">{{'chat.tabs.settings.info.email.text' | translate}}</p>
             <h5 class="font-size-14">{{'chat.tabs.settings.info.email.value' | translate}}</h5>
           </div>

           <div class="mt-4">
             <p class="text-muted mb-1">{{'chat.tabs.settings.info.time.text' | translate}}</p>
             <h5 class="font-size-14">{{'chat.tabs.settings.info.time.value' | translate}}</h5>
           </div>

           <div class="mt-4">
             <p class="text-muted mb-1">{{'chat.tabs.settings.info.location.text' | translate}}</p>
             <h5 class="font-size-14">{{'chat.tabs.settings.info.location.value' | translate}}</h5>
           </div>
         </ng-template>
       </ngb-panel>
       <ngb-panel cardClass="card shadow-none border mb-2" id="privacy">
         <ng-template ngbPanelTitle>
           <h5 class="font-size-14 m-0">
             {{'chat.tabs.settings.privacy.title' | translate}}
           </h5>
         </ng-template>
         <ng-template ngbPanelContent>
           <div class="py-3">
             <div class="media align-items-center">
               <div class="media-body overflow-hidden">
                 <h5 class="font-size-13 mb-0 text-truncate">{{'chat.tabs.settings.privacy.photo' | translate}}</h5>

               </div>
               <div class="dropdown ml-2" ngbDropdown>
                 <button class="btn btn-light btn-sm dropdown-toggle w-sm shadow-none" type="button" data-toggle="dropdown"
                   aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                   {{'chat.tabs.settings.privacy.dropdowntext' | translate}} <i class="mdi mdi-chevron-down"></i>
                 </button>
                 <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                   <a class="dropdown-item" href="#">{{'chat.tabs.settings.privacy.dropdown.everyone' | translate}}</a>
                   <a class="dropdown-item" href="#">{{'chat.tabs.settings.privacy.dropdown.selected' | translate}}</a>
                   <a class="dropdown-item" href="#">{{'chat.tabs.settings.privacy.dropdown.nobody' | translate}}</a>
                 </div>
               </div>
             </div>
           </div>
           <div class="py-3 border-top">
             <div class="media align-items-center">
               <div class="media-body overflow-hidden">
                 <h5 class="font-size-13 mb-0 text-truncate">{{'chat.tabs.settings.privacy.seen' | translate}}</h5>

               </div>
               <div class="ml-2">
                 <div class="custom-control custom-switch">
                   <input type="checkbox" class="custom-control-input" id="privacy-lastseenSwitch" checked>
                   <label class="custom-control-label" for="privacy-lastseenSwitch"></label>
                 </div>
               </div>
             </div>
           </div>

           <div class="py-3 border-top">
             <div class="media align-items-center">
               <div class="media-body overflow-hidden">
                 <h5 class="font-size-13 mb-0 text-truncate">{{'chat.tabs.settings.privacy.status' | translate}}</h5>

               </div>
               <div class="dropdown ml-2" ngbDropdown>
                 <button class="btn btn-light btn-sm dropdown-toggle w-sm shadow-none" type="button" data-toggle="dropdown"
                   aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                   {{'chat.tabs.settings.privacy.dropdowntext' | translate}} <i class="mdi mdi-chevron-down"></i>
                 </button>
                 <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                   <a class="dropdown-item" href="#">{{'chat.tabs.settings.privacy.dropdown.everyone' | translate}}</a>
                   <a class="dropdown-item" href="#">{{'chat.tabs.settings.privacy.dropdown.selected' | translate}}</a>
                   <a class="dropdown-item" href="#">{{'chat.tabs.settings.privacy.dropdown.nobody' | translate}}</a>
                 </div>
               </div>
             </div>
           </div>

           <div class="py-3 border-top">
             <div class="media align-items-center">
               <div class="media-body overflow-hidden">
                 <h5 class="font-size-13 mb-0 text-truncate">{{'chat.tabs.settings.privacy.receipt' | translate}}</h5>

               </div>
               <div class="ml-2">
                 <div class="custom-control custom-switch">
                   <input type="checkbox" class="custom-control-input" id="privacy-readreceiptSwitch" checked>
                   <label class="custom-control-label" for="privacy-readreceiptSwitch"></label>
                 </div>
               </div>
             </div>
           </div>

           <div class="py-3 border-top">
             <div class="media align-items-center">
               <div class="media-body overflow-hidden">
                 <h5 class="font-size-13 mb-0 text-truncate">{{'chat.tabs.settings.privacy.groups' | translate}}</h5>

               </div>
               <div class="dropdown ml-2" ngbDropdown>
                 <button class="btn btn-light btn-sm dropdown-toggle w-sm shadow-none" ngbDropdownToggle type="button"
                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                   {{ 'chat.tabs.settings.privacy.dropdowntext' | translate}} <i class="mdi mdi-chevron-down"></i>
                 </button>
                 <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                   <a class="dropdown-item" href="#">{{'chat.tabs.settings.privacy.dropdown.everyone' | translate}}</a>
                   <a class="dropdown-item" href="#">{{'chat.tabs.settings.privacy.dropdown.selected' | translate}}</a>
                   <a class="dropdown-item" href="#">{{'chat.tabs.settings.privacy.dropdown.nobody' | translate}}</a>
                 </div>
               </div>
             </div>
           </div>
         </ng-template>
       </ngb-panel>
       <ngb-panel cardClass="card shadow-none border mb-2" id="security">
         <ng-template ngbPanelTitle>
           <h5 class="font-size-14 m-0">
             {{'chat.tabs.settings.security.title' | translate}}
           </h5>
         </ng-template>
         <ng-template ngbPanelContent>
           <div>
             <div class="media align-items-center">
               <div class="media-body overflow-hidden">
                 <h5 class="font-size-13 mb-0 text-truncate">{{'chat.tabs.settings.security.text' | translate}}</h5>
               </div>
               <div class="ml-2">
                 <div class="custom-control custom-switch">
                   <input type="checkbox" class="custom-control-input" id="security-notificationswitch">
                   <label class="custom-control-label" for="security-notificationswitch"></label>
                 </div>
               </div>
             </div>
           </div>
         </ng-template>
       </ngb-panel>
       <ngb-panel cardClass="card shadow-none border mb-2" id="help">
         <ng-template ngbPanelTitle>
           <h5 class="font-size-14 m-0">
             {{'chat.tabs.settings.help.title' | translate}}
           </h5>
         </ng-template>
         <ng-template ngbPanelContent>
           <div>
             <div class="py-3">
               <h5 class="font-size-13 mb-0"><a href="#"
                   class="text-body d-block">{{'chat.tabs.settings.help.faqs' | translate}}</a>
               </h5>
             </div>
             <div class="py-3 border-top">
               <h5 class="font-size-13 mb-0"><a href="#"
                   class="text-body d-block">{{'chat.tabs.settings.help.contact' | translate}}</a></h5>
             </div>
             <div class="py-3 border-top">
               <h5 class="font-size-13 mb-0"><a href="#"
                   class="text-body d-block">{{'chat.tabs.settings.help.policy' | translate}}</a>
               </h5>
             </div>
           </div>
         </ng-template>
       </ngb-panel>
     </ngb-accordion>
     <!-- end profile-setting-accordion -->
   </perfect-scrollbar>
   <!-- End User profile description -->
 </div>
 <!-- Start Settings content -->
